import React, {Component} from 'react'
import {StyleSheet, View} from 'react-native'
import WebView from 'react-native-webview'
import {StackScreenProps} from '@react-navigation/stack'
import {SafeAreaView} from 'react-native-safe-area-context'

import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {pfHeaderHeight} from 'src/nav/Header/HeaderConstants'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'

type State = {
  loading: boolean
}
class PossibleWebView extends Component<
  StackScreenProps<MainStackParamList, 'PossibleWebView'>,
  State
> {
  state = {loading: true}

  onLoadStart = () => {
    this.setState({loading: true})
  }

  onLoadEnd = () => {
    this.setState({loading: false})
  }

  render() {
    const {route} = this.props
    const spinner = this.state.loading ? (
      <View style={StyleSheet.absoluteFill}>
        <Spinner />
      </View>
    ) : null
    return (
      <View style={{backgroundColor: genericPageBackground, flex: 1}}>
        <View style={{height: pfHeaderHeight}} />
        <SafeAreaView style={styles.webView}>
          <WebView
            onLoadStart={this.onLoadStart}
            onLoadEnd={this.onLoadEnd}
            style={styles.webView}
            source={route.params?.source}
            testID="Pf-Webview-Id"
          />
          {spinner}
        </SafeAreaView>
      </View>
    )
  }
}

export default PossibleWebView

const styles = StyleSheet.create({
  webView: {
    flex: 1,
    width: '100%',
  },
})
