import React, {FC} from 'react'
import {View, StyleSheet} from 'react-native'

import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type BaseListProps = {
  title?: string
  text: string
}

type IconListItem = BaseListProps & SvgIconProps

type ListItem = BaseListProps | IconListItem

export type ListVariantNames = 'bullets' | 'icon' | 'noIcon'

export type ListProps = {
  variant: ListVariantNames
  items: ListItem[]
}

const isIconListItem = (item: ListItem): item is IconListItem => {
  return 'name' in item && item.name !== undefined
}

const List: FC<ListProps> = (props) => {
  const {variant, items} = props

  const getLeftElement = (element: IconListItem): JSX.Element => {
    return (
      <View style={styles.leftElementStyle}>
        <SvgIcon
          name={element.name}
          colorVariant={element?.colorVariant ?? 'active'}
          size={'medium'}
        />
      </View>
    )
  }

  const getText = (title: string | undefined, text: string): JSX.Element => {
    let updatedText = title
    let updatedSubText = text
    if (variant === 'bullets') {
      const unicodeBullet = '\u2022'
      updatedText = updatedText ? `${unicodeBullet} ${title}` : title
      updatedSubText = title ? text : `${unicodeBullet} ${text}`
    }

    return (
      <View style={styles.textContainerStyle}>
        {title ? (
          <PFText textAlign={'left'} variant={'p_semibold'}>
            {updatedText}
          </PFText>
        ) : null}
        <PFText textAlign={'left'} variant={'p_sm'}>
          {updatedSubText}
        </PFText>
      </View>
    )
  }

  const getListItem = (element: ListItem): JSX.Element => {
    if (variant === 'icon' && isIconListItem(element)) {
      return (
        <View style={styles.containerStyle}>
          {getLeftElement(element)}
          {getText(element?.title, element.text)}
        </View>
      )
    } else {
      return getText(element?.title, element.text)
    }
  }

  return (
    <View>
      {items.map((element, index) => (
        <View style={styles.listItemStyle} key={index}>
          {getListItem(element)}
        </View>
      ))}
    </View>
  )
}
export default List

const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: 'row',
  },
  listItemStyle: {
    paddingBottom: 14,
  },
  leftElementStyle: {
    paddingRight: 8,
    paddingTop: 4,
  },
  textContainerStyle: {
    flex: 1,
  },
})
