import React, {FC, useCallback, useEffect, useMemo, useRef} from 'react'
import WebView from 'react-native-webview'
import {StyleSheet, View} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'
import {StackScreenProps} from '@react-navigation/stack'
import {useFocusEffect} from '@react-navigation/native'

import {isDeviceWeb} from 'src/lib/utils/platform'
import {ampli} from 'src/lib/Analytics/ampli'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

type Props = StackScreenProps<MainStackParamList, 'ZendeskSSOForm'> & {
  token?: string
}

const zendeskAuthenticationUrl = `https://possiblefinance1722620762.zendesk.com/access/jwt?return_to=https://possiblefinance1722620762.zendesk.com/hc/en-us/`

const ZendeskSSOFormFC: FC<Props> = (props) => {
  const {token} = props
  const formRef = useRef<HTMLFormElement | null>(null)

  useFocusEffect(
    useCallback(() => {
      sendAnalyticEvent(ampli.helpCenterRedirectSelected.bind(ampli))
    }, []),
  )

  useEffect(() => {
    // This behaviour is to submit the form when the component is mounted on web
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [formRef])

  const renderForm = useMemo(() => {
    if (isDeviceWeb()) {
      return (
        <View testID="Help-Center-Form-Web">
          <form method="post" ref={formRef} action={zendeskAuthenticationUrl}>
            <input type="hidden" name="jwt" value={token} />
          </form>
        </View>
      )
    }

    return (
      <WebView
        testID="Help-Center-Form"
        originWhitelist={['*']}
        source={{
          html: `
              <html>
                <body  onload="document.forms[0].submit();">
                  <form method="post" action="${zendeskAuthenticationUrl}">
                    <input type="hidden" name="jwt" value="${token}" />
                  </form>
                </body>
              </html>
            `,
        }}
      />
    )
  }, [token])
  return <SafeAreaView style={styles.webView}>{renderForm}</SafeAreaView>
}
const ZendeskSSOForm = withForwardedNavigationParams<Props>()(ZendeskSSOFormFC)

export {ZendeskSSOForm}

const styles = StyleSheet.create({
  webView: {
    flex: 1,
    width: '100%',
  },
})
