import {Keyboard, Linking} from 'react-native'

import AppNav from 'src/nav/AppNavActions'
import Log from 'src/lib/loggingUtil'
import {MainStackNavigationProp, MainStackParamList} from 'src/nav/MainStackParamsList'
import {isDeviceWeb} from 'src/lib/utils/platform'

export type LinkSourceType = {
  uri: string
}

export function PopPage(navigation: MainStackNavigationProp): void {
  AppNav.pop(navigation)
}

export function PushPage(
  navigation: MainStackNavigationProp,
  routeName: keyof MainStackParamList,
  args = {},
): void {
  AppNav.push(navigation, routeName, args)
}

export function PushMainPage(routeName: keyof MainStackParamList, args = {}): void {
  AppNav.pushToMainStack(routeName, args)
}

export function SetRoot(routeName: keyof MainStackParamList, params = {}): void {
  SetRootStack([{routeName, params}])
}

export function SetRootStack(
  routeStack: Array<{routeName: keyof MainStackParamList; params?: Record<string, unknown>}>,
): void {
  Keyboard.dismiss()
  if (!routeStack || routeStack.length === 0) {
    Log.log('Attempting to set root to invalid component')
    return
  }

  AppNav.resetMainNavigation(routeStack)
}

export function PushWebPageOnStack(
  navigation: MainStackNavigationProp,
  source: LinkSourceType,
): void {
  if (!source) {
    Log.log('PushWebPageOnStack params invalid')
    return
  }

  if (isDeviceWeb()) {
    window.open(source.uri, '_blank')
    return
  }
  AppNav.push(navigation, 'PossibleWebView', {source})
}

export function OpenWebPage(source: LinkSourceType): void {
  if (!source) {
    Log.log('PushWebPage params invalid')
    return
  }

  if (isDeviceWeb()) {
    window.open(source.uri, '_blank')
    return
  }

  void Linking.openURL(source.uri)
}
