import React from 'react'
import {StyleSheet, View} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {littleGap, smallGap} from 'src/designSystem/layout'
import {Color} from 'src/designSystem/types'

type MinPayTableHeaderProps = {
  headerTitle?: string
  pillContent?: string
  pillColor?: Color
  headerContent?: string | React.ReactElement
}

export const MinPayTableHeader: React.FC<MinPayTableHeaderProps> = ({
  headerTitle,
  pillContent,
  pillColor,
  headerContent,
}) => {
  return (
    <View style={styles.headerContainer}>
      <View style={styles.headerRow}>
        {headerTitle ? <PFText variant="p_lg_semibold">{headerTitle}</PFText> : null}
        {pillContent ? (
          <PFStatusPill text={pillContent} color={pillColor} fontColor={NamedColors.WHITE} />
        ) : null}
      </View>
      {typeof headerContent === 'string' ? (
        <PFText variant="p">{headerContent}</PFText>
      ) : (
        headerContent
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    marginBottom: smallGap,
  },
  headerRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: littleGap,
  },
})
