import {DestinationValues, Destinations} from 'src/lib/utils/events'
import {NavContextType} from 'src/nav/NavContext.types'

export const initialContext: NavContextType = {
  hasProcessedParams: false,
  tempAuthToken: null,
  tempAuthUserId: null,
  handledTempAuthToken: false,
  destination: undefined,
  handledDestination: false,
}

type DefineDestinationParameters = Pick<
  NavContextType,
  'tempAuthToken' | 'destination' | 'initialParams'
>
type DefineDestinationReturn = Pick<
  NavContextType,
  'destination' | 'tempAuthToken' | 'tempAuthUserId'
> | null

export const defineDestination = ({
  initialParams,
  tempAuthToken,
  destination,
}: DefineDestinationParameters): DefineDestinationReturn => {
  if (!initialParams) {
    return null
  }
  let authToken: string | null = null
  let tempAuthUserId: string | null = null
  let foundDestination: Destinations | 'none' = 'none'

  if (!tempAuthToken && initialParams.has('userId') && initialParams.has('tempToken')) {
    authToken = initialParams.get('tempToken')
    tempAuthUserId = initialParams.get('userId')
  }

  if (!destination) {
    if (initialParams.has('code') && initialParams.has('email')) {
      foundDestination = 'verify-email'
    } else if (initialParams.has('contactus')) {
      foundDestination = 'contactus'
    } else {
      for (const d of DestinationValues) {
        if (initialParams.get(d) === 'true') {
          foundDestination = d
          break
        }
      }
    }
  }

  return {
    destination: foundDestination,
    tempAuthToken: authToken,
    tempAuthUserId,
  }
}
