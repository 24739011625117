import React, {FC, useCallback, useEffect} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {UserGenerateZendeskTokenDocument} from 'src/products/general/ZendeskHelpCenter/queries/UserGenerateZendeskToken.gqls'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import List from 'src/designSystem/components/molecules/List/List'
import AppNav from 'src/nav/AppNavActions'

type Props = StackScreenProps<MainStackParamList, 'ZendeskHelpCenter'>

const ZendeskHelpCenter: FC<Props> = () => {
  const [generateZdToken, {loading: isLoadingZdToken, data: zdTokenData}] = useCassandraMutation(
    UserGenerateZendeskTokenDocument,
  )
  const navigation = useNavigation()

  useFocusEffect(
    useCallback(() => {
      sendAnalyticEvent(ampli.helpCenterRedirectViewed.bind(ampli))
    }, []),
  )

  useEffect(() => {
    void generateZdToken()
  }, [generateZdToken])

  const submit = () => {
    AppNav.push(navigation, 'ZendeskSSOForm', {token: zdTokenData?.userGenerateZendeskToken.token})
  }
  const {t} = useTranslation(['ZendeskHelpCenter', 'Common'])

  const action = {
    onPress: submit,
    text: t('Common:Continue'),
    disabled: isLoadingZdToken,
  }

  const listItems = [
    {
      title: t('ListTitle1'),
      text: t('ListText1'),
      name: 'search',
    },
    {
      title: t('ListTitle2'),
      text: t('ListText2'),
      name: 'message',
    },
    {
      title: t('ListTitle3'),
      text: t('ListText3'),
      name: 'chat',
    },
  ]
  return (
    <Page
      variant="generic"
      buttonProps={buttonLockupProperties(action)}
      title={t('HelpCenterTitle')}
      testID="ZendeskHelpCenterPage"
      description={t('HelpCenterDescription')}
      smallTopGap
    >
      <List variant={'icon'} items={listItems} />
    </Page>
  )
}

export {ZendeskHelpCenter}
